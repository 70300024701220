import { routerlinks } from './routerlinks/routerlinks-nl'
import { prod } from './envs/prod'
import { Environment } from './environment.interface'
import { baseEnvironment } from './base.environment'

export const environment: Environment = {
  ...baseEnvironment,
  environmentName: prod.environmentName,
  production: prod.production,
  baseUrl: prod.baseUrl,
  isDevelopmentMachine: prod.isDevelopmentMachine,
  ng2ProductionMode: prod.ng2ProductionMode,
  verboseDebugOutput: prod.verboseDebugOutput,
  deployTestScreens: prod.deployTestScreens,
  routerLinks: routerlinks,
  defaultLanguage: 'nl',
  firebase: prod.firebase,
  intercomAppId: prod.intercomAppId,
  apiUrl: prod.apiUrl,
  clientId: prod.clientId,
  clientSecret: prod.clientSecret,
  kvkApiUrl: prod.kvkApiUrl,
  billingServiceApiUrl: prod.billingServiceApiUrl,
  stripeAntiCancellationCoupon: prod.stripeAntiCancellationCoupon,
  taxServiceApiUrl: prod.taxServiceApiUrl,
  bankServiceApiUrl: prod.bankServiceApiUrl,
  segmentApiKey: prod.segmentApiKey,
  baremetricsCancellationAccessToken: prod.baremetricsCancellationAccessToken,
  versionWorkerApiUrl: prod.versionWorkerApiUrl,
  userflowToken: prod.userflowToken,
  paymentsServiceApiUrl: prod.paymentsServiceApiUrl,
  agerasFinanceAppUrl: prod.agerasFinanceAppUrl,
  integrationsServiceApiKey: prod.integrationsServiceApiKey,
  integrationsServiceApiUrl: prod.integrationsServiceApiUrl,
  billingSwitchDate: prod.billingSwitchDate,
  basisUpsellPrice: prod.basisUpsellPrice,
  plusUpsellPrice: prod.plusUpsellPrice,
  completeUpsellPrice: prod.completeUpsellPrice,
  swanBankingUrl: prod.swanBankingUrl,
  laravelServiceApiUrl: prod.laravelServiceApiUrl,
  recaptchaSiteKey: prod.recaptchaSiteKey,
}
